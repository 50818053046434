@import "src/view/styles/colors";

.popup-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .popup-title-wrapper {
    display: flex;
    justify-content: space-between;

    .arrow-left-icon {
      padding-right: 5px;
    }
  }

  .popup-title {
    color: color($var-color-app-dark-blue);
    font-weight: 700;
    font-size: 24px;
    line-height: 29.5px;
    letter-spacing: -1.5px;
  }

  .popup-description {
    padding-top: 8px;

    color: color($var-color-app-dark-blue);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  button {
    display: flex;
  }
}
