@import "src/view/styles/colors";

.wrapper {
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 16px;

  cursor: pointer;

  & > :not(svg) {
    color: color($var-color-app-grey-2);
  }

  &.selected,
  &.half-checked,
  &:hover {
    & > :not(svg) {
      color: color($var-color-app-black);
    }
  }
}
