.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.report-builder {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
}
