.basic-multi-select {
  width: 100%;

  .option {
    //

    &.hidden {
      display: none;
    }

    &.selected,
    &:hover {
      color: #202020;
    }
  }

  .menu {
    min-width: 280px;
  }
}
