.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  width: 100vw;
  height: 100vh;

  .bg {
    width: 100%;
    height: 100%;

    background-color: rgb(46 49 56 / 80%);
  }
}
