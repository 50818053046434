@import "src/view/styles/colors";

.no-options {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;

  color: color($var-color-app-grey-2);
}

.tab-radio {
  position: relative;

  display: flex;
  width: 100%;

  min-height: 34px;
  overflow: hidden;

  border: 1px solid color($var-color-app-grey-1);
  border-radius: 8px;

  .active-tab-highlight-container {
    position: absolute;

    top: 0;

    height: 100%;
    padding: 1px;

    transition: left 0.15s ease-in-out;

    .highlight {
      width: 100%;
      height: 100%;

      background-color: color($var-color-app-blue-grey-1);
      border-radius: 8px - 1px;
    }
  }

  label {
    position: relative;
    z-index: 0;

    display: flex;

    .option-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 8px;

      color: color($var-color-app-grey-2);
      text-align: center;

      cursor: pointer;

      transition: color 0.1s ease-in-out;
    }

    &:hover .option-item {
      color: color($var-color-app-blue-main);

      transition: none;
    }

    input[type="radio"]:checked ~ .option-item {
      color: color($var-color-app-blue-main);

      cursor: default;
    }

    input[type="radio"]:disabled ~ .option-item {
      color: color($var-color-app-grey-2);

      cursor: default;
    }
  }

  &.disabled .active-tab-highlight-container .highlight {
    background-color: color($var-color-app-grey-3);
  }

  &.error {
    border: 1px solid color($var-color-app-orange);
  }
}
