@import "src/view/styles/colors";

.column-list {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;

  .header-wrapper {
    .header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      margin-bottom: 15px;

      .search-wrapper {
        width: 270px;
      }

      .selectors {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding-left: 10px;

        button {
          display: flex;
          align-items: center;
          width: fit-content;

          font-weight: 700;

          .icon-wrapper {
            display: flex;
            align-items: center;
            height: 100%;
            margin-right: 6px;
          }

          &.select-all {
            color: color($var-color-app-blue-main);
          }

          &.deselect-all {
            color: color($var-color-app-orange);
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .column-list-header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 20px;
      padding: 12px 0;

      .side {
        display: flex;
        justify-content: space-between;
        padding: 0 14px 0 10px;

        span {
          color: color($var-color-app-dark-blue);
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .no-columns {
      padding-top: 14px;

      color: color($var-color-app-grey-2);
    }
  }

  .list-wrapper {
    position: relative;

    margin-bottom: 20px;

    .list {
      position: absolute;

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 5px 20px;
      width: calc(100% + 10px);
      max-height: 100%;
      padding-right: 10px;
      padding-bottom: 10px;

      overflow: hidden auto;

      .column {
        height: fit-content;
        padding-right: 14px;

        padding-left: 10px;

        border: 1px solid color($var-color-app-grey-1);
        border-radius: 5px;

        .main-part {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 46px;

          .column-checkbox {
            //

            .column-name {
              max-width: 170px;
              overflow: hidden;

              font-weight: 600;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .filter-checkbox {
            //
          }
        }

        .extra-part {
          //

          .numeric-options {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 12px 0 12px 10px;

            border-top: 1px solid color($var-color-app-grey-3);

            .numeric-option-checkbox {
              //

              .numeric-option-checkbox-label {
                font-size: 12px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
