.filter-item {
  //

  .filter-name {
    overflow: hidden;

    color: #454a54;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.date,
  &.select,
  &.checkbox {
    width: 180px;
  }
}
