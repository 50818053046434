@import "src/view/styles/colors";

.report-builder-header {
  z-index: 10;

  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr auto;
  gap: 0 20px;
  margin: 14px 16px 16px;
  padding: 16px;

  background-color: color($var-color-app-white);
  border-radius: 8px;

  box-shadow: 0 9px 18px 0 #454a541a;

  .controls {
    display: grid;
    grid-template-columns: 200px repeat(4, auto);
    gap: 10px;
    height: 100%;
    padding-top: 20px;
  }
}
