@import "colors";
@import "mixins";
@import "ag-grid";
@import "react-toastify";

:root {
  @each $key, $value in $app-colors {
    --color-#{$key}: #{$value};
  }
}

* {
  @include custom-scrollbar(color($var-color-app-grey-1));

  &:hover {
    @include custom-scrollbar(color($var-color-app-blue-main));
  }

  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-size: 14px;
  font-family: Arial, sans-serif;

  outline: none;
}

html,
body {
  height: 100vh;
  overflow: hidden;
}

#root {
  height: 100%;
}

button {
  cursor: pointer;
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;

  border: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}
