.fake-checkbox {
  display: flex;
  align-items: center;
  width: fit-content;

  .icon-wrapper {
    padding-right: 8px;
  }

  .label {
    //
  }

  &.disabled {
    //
  }
}
