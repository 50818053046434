.popup-header-empty {
  display: flex;
  justify-content: flex-end;

  .close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 3px;
  }
}
