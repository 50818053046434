@import "src/view/styles/colors";

.report-menu {
  //

  .menu-button {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    border: 1px solid color($var-color-app-grey-1);
    border-radius: 8px;

    cursor: pointer;
  }

  .menu-item-wrapper {
    padding: 0 20px 0 16px;

    background-color: color($var-color-app-dark-blue-2);

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    .menu-item {
      display: flex;
      align-items: center;

      svg {
        color: color($var-color-app-grey-2);
      }

      span {
        padding-left: 12px;

        color: color($var-color-app-grey-2);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      &.hovered {
        //

        svg,
        span {
          color: white;
        }
      }

      &.disabled {
        cursor: not-allowed;

        svg,
        span {
          color: color($var-color-app-grey-5);
        }
      }
    }
  }
}
