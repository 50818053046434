@import "src/view/styles/colors";

.iframe-fullscreen-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31px;
  height: 31px;
  margin: 15px 0 8px;

  svg {
    color: color($var-color-app-grey-2);
  }

  &:hover svg {
    color: color($var-color-app-blue-main);
  }
}
