@import "src/view/styles/colors";

.filters-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  background-color: color($var-color-app-white);
  border: 1px solid color($var-color-app-grey-1);
  border-radius: 8px;
}
