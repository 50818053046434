@import "src/view/styles/colors";

.draggable-filter {
  padding: 0 8px;

  background-color: color($var-color-app-white);

  border: 1px solid transparent;
  border-radius: 8px;

  transition: border-color 0.2s ease-in-out;

  .item-header {
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    gap: 8px;
    align-items: center;
    min-height: 36px;
    margin-bottom: 8px;

    .dnd-icon {
      display: flex;
      align-items: center;
      width: fit-content;
      padding: 6px 0;

      cursor: grab;
    }

    .pin-icon {
      display: flex;
      align-items: center;
      padding: 6px 0;

      svg {
        color: color($var-color-app-blue-main);
      }

      &.pinned svg {
        fill: currentcolor;
      }
    }

    .name {
      font-weight: 600;
      line-height: 20px;
    }

    .description {
      display: flex;
      align-items: center;

      cursor: pointer;
    }
  }

  .item-body {
    margin-bottom: -1px;
    padding-bottom: 20px;

    border-bottom: 1px solid color($var-color-app-grey-1);
  }

  &.is-dragging {
    border-color: color($var-color-app-grey-1);
  }
}
