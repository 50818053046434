@import "src/view/styles/colors";

.date-picker-base {
  position: relative;

  width: 100%;

  .input {
    display: flex;
    width: 100%;

    label {
      display: block;
      width: 100%;
      height: 40px;

      cursor: pointer;

      .icon {
        position: absolute;
        top: 12px;
        left: 16px;

        color: color($var-color-app-dark-blue-3);

        svg {
          font-size: 16px;
        }
      }

      .input-label {
        font-size: 12px;
        line-height: 20px;
      }

      input[type="text"] {
        width: 100%;
        height: 100%;
        padding: 0 44px;

        font-weight: normal;
        font-size: 14px;

        background-color: color($var-color-app-white);
        border: 1px solid color($var-color-app-grey-1);
        border-radius: 8px;

        caret-color: transparent;

        &::placeholder {
          color: color($var-color-app-grey-2);
          font-size: 14px;
        }

        &:focus {
          border-color: color($var-color-app-grey-2);
        }

        &:disabled {
          border-color: color($var-color-app-grey-1) !important;
        }

        &.error {
          border-color: color($var-color-app-orange);
        }
      }
    }

    .clear-icon {
      position: absolute;
      right: 0;
      z-index: 0;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 100%;

      &.hidden {
        display: none;
      }
    }
  }

  .calendar {
    padding: 20px;

    border-color: color($var-color-app-grey-2);
    border-radius: 10px;

    box-shadow: 0 6px 24px 0 #2729371a;

    .header {
      padding-bottom: 20px;

      background-color: color($var-color-app-white);
      border-bottom: 1px solid rgb(39 41 55 / 12%);

      user-select: none;

      .month-control-icon {
        position: absolute;

        color: color($var-color-app-black-2);

        cursor: pointer;

        &-left {
          left: 0;
        }

        &-right {
          right: 0;
        }
      }

      .current-date {
        color: color($var-color-app-black-2);
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
      }
    }

    &.inline {
      padding: 0 3px;

      border-color: transparent;
      border-radius: 0;

      box-shadow: none;
    }
  }

  &.is-open {
    z-index: 100;
  }
}
