@import "src/view/styles/colors";

.person {
  display: flex;
  align-items: center;

  white-space: nowrap;

  cursor: pointer;

  .avatar {
    position: relative;

    width: 33px;
    height: 33px;

    overflow: hidden;

    background-color: color($var-color-app-bg);

    border-radius: 50%;

    .image-logo {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background-repeat: no-repeat;
      background-size: cover;
    }

    .abbreviation {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      color: color($var-color-app-dark-blue) !important;

      line-height: 20px;
    }
  }

  .name-wrapper {
    margin-left: 10px;

    .name {
      width: 115px;
      overflow: hidden;

      color: color($var-color-app-dark-blue);
      font-weight: 700;
      line-height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .position {
      width: 115px;
      overflow: hidden;

      color: color($var-color-app-black-2);
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
