.ag-root-wrapper {
  font-family: Arial, sans-serif;

  background: transparent !important;
  border: none !important;
  border-radius: 8px 8px 0 0 !important;
}

.ag-body-horizontal-scroll-viewport,
.ag-body-vertical-scroll-viewport {
  background: white !important;
}

.ag-root-wrapper-body {
  background: transparent !important;
}

.ag-header {
  background: #eaf3f4 !important;
  border: none !important;

  .ag-header-cell {
    padding: 0 12px;
  }

  .ag-header-cell-resize {
    width: 16px;

    &::after {
      left: 7px !important;

      width: 2px !important;

      background: #adb4bd !important;
    }
  }
}

.ag-icon {
  color: #adb4bd !important;
}

.ag-row:not(.ag-row-pinned) {
  background: transparent !important;
  border-top: 8px solid transparent;
  border-bottom: none !important;

  .ag-cell {
    padding: 0 12px;

    line-height: 52px;

    background: white;
  }

  .ag-checkbox-cell {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.ag-floating-bottom {
  border-top: 1px solid #eff2f5 !important;

  .ag-row {
    border-bottom: 1px solid #eff2f5 !important;
  }

  .ag-checkbox {
    display: none !important;
  }
}

.ag-row.ag-row-pinned {
  //

  .ag-cell {
    padding: 0 12px;

    font-weight: 600;
    font-size: 12px;

    background: white;
  }
}

.lock-header {
  //

  .ag-header-cell-label {
    justify-content: center;
  }
}

.ag-pinned-left-header,
.ag-pinned-left-cols-container,
.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right, .ag-cell-range-single-cell) {
  border-right: 1px solid #eff2f5 !important;
}

.ag-pinned-right-header,
.ag-pinned-right-cols-container {
  border-left: 1px solid #eff2f5 !important;
}

.ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left, .ag-cell-range-single-cell) {
  border-left: none !important;
}

.ag-body-horizontal-scroll:not(.ag-scrollbar-invisible) .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
  background: white;
  border-right: #eff2f5 !important;
}

.ag-body-horizontal-scroll:not(.ag-scrollbar-invisible) .ag-horizontal-right-spacer:not(.ag-scroller-corner) {
  background: white;
  border-left: #eff2f5 !important;
}

.ag-paging-panel {
  height: 40px !important;

  background: #eaf3f4 !important;
  border: none !important;
}
