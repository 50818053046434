@import "src/view/styles/colors";

.side-menu-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;

  background-color: color($var-color-app-white);
  box-shadow: -2px 2px 36px 0 #061F331F;
}
