@import "src/view/styles/colors";

.slide-checkbox {
  display: flex;
  align-items: center;
  height: 100%;

  label {
    .input-checkbox {
      display: flex;
    }
    cursor: pointer;

    .checkbox-wrapper {
      position: relative;

      box-sizing: border-box;

      width: 40px;
      height: 22px;
      margin-left: 8px;

      background-color: color($var-color-app-white);

      border: 1px solid color($var-color-app-grey-2);
      border-radius: 11px;

      .circle {
        position: absolute;
        top: 1px;
        left: 1px;

        width: 18px;
        height: 18px;

        background-color: color($var-color-app-grey-2);
        border-radius: 50%;

        transition: background-color 0.15s ease-in-out, left 0.15s ease-in-out;
      }
    }

    input[type="checkbox"] {
      display: none;

      &:checked {
        &~.checkbox-wrapper .circle {
          left: calc(100% - 19px);

          background-color: color($var-color-app-dark-blue);
        }
      }

      &:disabled {
        &~.checkbox-wrapper .circle {
          background-color: color($var-color-app-grey-2);
        }
      }
    }

    &.disabled {
      display: none;
    }
  }
}
