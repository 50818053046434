@import "src/view/styles/colors";

.basic-popup {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  max-height: 100%;
  padding: 20px;

  pointer-events: none;

  .popup {
    min-width: 250px;
    height: fit-content;
    max-height: 100%;

    background-color: white;

    border-radius: 8px;

    pointer-events: all;
  }
}
