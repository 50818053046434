// stylelint-disable selector-class-pattern
@import "src/view/styles/colors";
@import "react-datepicker/dist/react-datepicker.css";

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__portal {
  background-color: rgb(46 49 56 / 80%);
}

.react-datepicker-popper {
  // padding-top: 4px !important;

  & > div {
    display: flex;
  }
}

.react-datepicker__header {
  padding: 0 !important;

  background-color: transparent !important;
  border-bottom: none !important;
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__day-names {
  margin-top: 10px;
  margin-bottom: 0;
}

.react-datepicker__week {
  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.react-datepicker__day {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin: 0;

  border-radius: 0;

  &:not(:last-child) {
    margin-right: 4px !important;
  }

  &-name {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    margin: 0;

    font-size: 12px;

    &:not(:last-child) {
      margin-right: 4px !important;
    }
  }

  &:not(&--selecting-range-start, &--range-start, &--selecting-range-end, &--range-end, &--excluded) {
    //

    &.react-datepicker__day--in-selecting-range,
    &.react-datepicker__day--in-range {
      color: color($var-color-app-dark-blue-3);

      background-color: #e0ebed;
      border-radius: 0;

      &:not(:first-child) {
        position: relative;

        &::before {
          position: absolute;
          right: 100%;
          z-index: 0;

          width: 10px;
          height: 100%;

          background: #e0ebed;

          content: "";
        }
      }

      &:not(:last-child) {
        position: relative;

        &::after {
          position: absolute;
          left: 100%;
          z-index: 0;

          width: 10px;
          height: 100%;

          background: #e0ebed;

          content: "";
        }
      }

      &:hover {
        z-index: 2;

        background-color: #cddee1 !important;
      }
    }
  }

  &--selected,
  &--range-start,
  &--selecting-range-start,
  &--range-end,
  &--selecting-range-end {
    position: relative;
    z-index: 5;

    color: color($var-color-app-white) !important;

    &:not(&--excluded) {
      background-color: color($var-color-app-dark-blue-3) !important;
      border-radius: 8px;
    }
  }

  &--excluded {
    pointer-events: none;
  }

  &--excluded-start,
  &--excluded-end {
    position: relative;
    z-index: 10;

    color: color($var-color-app-white) !important;

    background-color: color($var-color-app-orange) !important;
    border-radius: 8px !important;
  }

  &:not(&--excluded-start, &--excluded-end) {
    //

    &.react-datepicker__day--excluded {
      color: color($var-color-app-dark-blue);

      background-color: #f5d4cf;
      border-radius: 0;

      &:not(:first-child) {
        position: relative;

        &::before {
          position: absolute;
          right: 100%;
          z-index: 0;

          width: 10px;
          height: 100%;

          background: #f5d4cf;

          content: "";
        }
      }

      &:not(:last-child) {
        position: relative;

        &::after {
          position: absolute;
          left: 100%;
          z-index: 0;

          width: 10px;
          height: 100%;

          background: #f5d4cf;

          content: "";
        }
      }
    }
  }

  &--outside-month {
    color: rgb(39 41 55 / 35%);
  }

  &--keyboard-selected {
    z-index: 3;

    color: color($var-color-app-white) !important;

    background-color: color($var-color-app-blue-main) !important;
    border-radius: 8px;

    &:hover:not(.react-datepicker__day--in-range, .react-datepicker__day--in-selected-range) {
      color: color($var-color-app-white);

      background-color: color($var-color-app-blue-main) !important;
      border-radius: 8px;
    }
  }

  &:hover {
    color: color($var-color-app-dark-blue-3);

    background-color: #e0ebed;
    border-radius: 8px;
  }
}
