.edit-report-filters-popup {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  height: 100%;

  .popup-header {
    padding: 16px 16px 12px;
  }
}
