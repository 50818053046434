@import "src/view/styles/colors";

.search-input {
  display: flex;
  flex-direction: column;

  label {
    display: block;

    .input-label {
      line-height: 20px;
    }

    .input {
      position: relative;

      display: flex;

      .search-icon,
      .clear-icon {
        position: absolute;
        top: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 100%;
      }

      .search-icon {
        left: 0;

        pointer-events: none;
      }

      .clear-icon {
        right: 0;
      }

      input[type="text"] {
        width: 100%;
        height: 40px;
        padding: 0 40px;

        line-height: 20px;

        background-color: color($var-color-app-white);

        border: 1px solid color($var-color-app-grey-1);
        border-radius: 8px;

        &::placeholder {
          color: color($var-color-app-grey-2);
        }

        &:focus {
          border-color: color($var-color-app-grey-2);
        }

        &:disabled {
          border-color: color($var-color-app-grey-1) !important;
        }
      }
    }
  }
}
