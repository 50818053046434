.report-builder-table {
  height: 100%;
  padding: 0 16px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
