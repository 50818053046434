.menu-list-multi {
  //
}

.all-checkbox {
  padding: 12px 16px;

  color: #adb4bd;

  cursor: pointer;

  &.selected,
  &:hover {
    color: #202020;
  }
}
