@import "src/view/styles/colors";

.draggable-filters-list {
  height: 100%;

  .filters-list {

    height: 100%;
    padding: 0 8px;
    overflow: hidden auto;

    .drop-list {
      min-height: 100%;
      padding: 16px 0;

      .drag-item {
        padding-bottom: 12px;

        &.hidden {
          display: none;
        }
      }
    }
  }

  .delimiter {
    height: 1px;
    margin-top: 10px;

    background-color: color($var-color-app-grey-1);

    &.hidden {
      visibility: hidden;
    }
  }
}

.empty {
  padding: 16px;

  color: color($var-color-app-grey-2);
}
