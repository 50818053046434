@import "src/view/styles/colors";

.ghost-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;

  color: color($var-color-app-blue-light);
  font-weight: 600;
  line-height: 20px;
  text-align: center;

  background-color: color($var-color-app-white);
  border: 1px solid color($var-color-app-grey-1);
  border-radius: 8px;

  &:hover {
    color: color($var-color-app-blue-main);
  }

  &:disabled {
    color: color($var-color-app-grey-2);

    background-color: color($var-color-app-blue-grey-1);
    border-color: color($var-color-app-blue-grey-1);

    pointer-events: none;
  }
}
