.loading,
.error,
.no-report-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading {
  //
}

.error {
  //
}
