.side-popup {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  pointer-events: none;

  .popup {
    width: 408px;
    height: 100%;

    background-color: white;

    pointer-events: all;
  }
}
