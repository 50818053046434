@import "src/view/styles/colors";

.date-picker-control {
  position: relative;

  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  overflow: hidden;

  background-color: color($var-color-app-white);

  border: 1px solid color($var-color-app-grey-1);
  border-radius: 8px;

  .input {
    width: 100%;
    height: 100%;

    .input-button {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding-right: 42px;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px 0 15px;

        color: color($var-color-app-dark-blue-3);
      }

      .value {
        //
      }

      .placeholder {
        color: color($var-color-app-grey-2);
      }

      .value,
      .placeholder {
        overflow: hidden;

        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .clear-button {
    position: absolute;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 100%;
  }

  &.focus {
    border-color: color($var-color-app-grey-2);
  }

  &.empty {
    //

    .input .input-button {
      padding-right: 16px;
    }
  }
}
