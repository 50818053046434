@import "src/view/styles/colors";

.basic-checkbox {
  display: flex;
  align-items: center;

  label {
    display: flex;
    width: fit-content;
    margin: 0;

    cursor: pointer;

    .checkbox-wrapper {
      padding-right: 8px;
    }

    .label-text {
      display: flex;
      align-items: center;
      max-width: 100%;

      user-select: none;
    }

    &.disabled {
      cursor: default;
    }
  }
}
