@import "src/view/styles/colors";

.side-menu {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 270px;
  height: 100%;

  background-color: color($var-color-app-white);
  border-top-right-radius: 8px;
  box-shadow: 7px 4px 81px 0 #0000001a;

  .iframe-fullscreen {
    min-height: 14px;
    padding: 0 16px;
  }

  .side-menu-wrapper {
    position: relative;
    z-index: 20;

    height: 100%;

    .collapse-menu-button-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }

    .side-menu-content {
      display: grid;
      grid-template-rows: auto 1fr auto;
      height: 100%;

      .side-menu-header-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0 16px 16px;

        .header-title {
          display: flex;
          align-items: center;
          min-height: 32px;
          padding-right: 40px;

          color: color($var-color-app-dark-blue);
          font-weight: 700;
          font-size: 14px;
        }
      }

      .side-menu-reports-wrapper {
        position: relative;

        .list {
          position: absolute;
          top: 0;
          left: 0;

          width: 100%;
          height: 100%;

          .reports-loading {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  &.is-menu-collapsed {
    width: 35px;

    .iframe-fullscreen {
      padding: 0 0 0 2px;
    }

    .collapse-menu-button-wrapper {
      right: unset;
      left: 0;
    }

    .side-menu-wrapper .side-menu-content {
      display: none;
    }
  }
}
