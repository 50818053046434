@import "src/view/styles/colors";

.data-table {
  position: relative;

  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;

  .loading {
    position: absolute;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    background-color: rgb(255 255 255 / 60%);
  }

  .total {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    padding: 0 16px;

    font-weight: 600;
    white-space: nowrap;

    background-color: color($var-color-app-blue-grey-1);
  }
}

.no-columns {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  user-select: none;
}
