.report-basic-form {
  width: 100%;

  form {
    //

    .name-input {
      margin-bottom: 10px;
    }

    .source-input {
      margin-bottom: 10px;
    }

    .type-input {
      margin-bottom: 16px;
    }
  }
}
