@import "src/view/styles/colors";

.min-max-input {
  display: flex;
  gap: 10px;

  .input-block-wrapper {
    display: flex;
    align-items: center;

    .divider {
      padding-right: 4px;
      padding-left: 4px;

      color: color($var-color-app-blue-light);
    }

    .input-block {
      //

      input[type="text"] {
        width: 40px;
        height: 40px;

        font-size: 14px;
        line-height: 40px;
        text-align: center;

        border: 1px solid color($var-color-app-grey-1);
        border-radius: 8px;

        &:focus {
          border-color: color($var-color-app-grey-2);
        }
      }
    }
  }

  .input-control {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: space-between;
    height: 40px;
  }
}
