@import "src/view/styles/colors";

.report-filters-form {
  overflow: hidden;

  form {
    display: flex;
    flex-direction: column;
    height: 100%;

    .form-header {
      padding-bottom: 20px;

      border-bottom: 1px solid color($var-color-app-grey-1);

      .search-wrapper {
        padding: 0 16px;
      }
    }

    .filters {
      flex: 1;

      overflow: hidden;

      .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }

    .form-footer {
      padding: 16px;

      background-color: color($var-color-app-white);
      border-top: 1px solid color($var-color-app-grey-3);
    }
  }
}
