@import "src/view/styles/colors";

.report-details-tooltip {
  padding: 12px 18px 12px 12px;

  .header {
    padding-bottom: 8px;

    color: color($var-color-app-white);
    font-size: 12px;
    line-height: 20px;
  }

  .tooltip-body {
    display: flex;
    flex-direction: column;
    padding-left: 6px;

    .tooltip-line {
      color: color($var-color-app-grey-2);
      font-size: 12px;
      line-height: 20px;

      .dot-line {
        padding-right: 4px;

        font-size: 12px;
      }
    }
  }
}
