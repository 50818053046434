@import "src/view/styles/colors";

.collapse-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  background-color: color($var-color-app-bg);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  &.collapsed {
    transform: rotate(-180deg);
  }
}
