@import "src/view/styles/colors";

.basic-number-input {
  display: flex;
  flex-direction: column;

  label {
    display: block;

    .input-label {
      line-height: 20px;
    }

    input[type="text"] {
      width: 100%;
      height: 40px;
      padding: 0 16px;

      background-color: color($var-color-app-white);

      border: 1px solid color($var-color-app-grey-1);
      border-radius: 8px;

      &::placeholder {
        color: color($var-color-app-grey-2);
        font-size: 14px;
      }

      &:focus {
        border-color: color($var-color-app-grey-2);
      }

      &:disabled {
        border-color: color($var-color-app-grey-1) !important;
      }

      &.error {
        border-color: color($var-color-app-orange);
      }
    }
  }
}
