@import "./colors";

@mixin custom-scrollbar($thumb-color: lightgrey, $track-color: transparent, $width: 14px, $height: 14px) {
  &::-webkit-scrollbar {
    width: $width;
    height: $height;
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
    border: 4px solid color($var-color-app-white);
    border-radius: 7px;
  }

  scrollbar-width: thin;
  scrollbar-color: $thumb-color $track-color;
}

@mixin custom-scrollbar-inverse($thumb-color: color($var-color-app-white), $track-color: transparent, $width: 14px, $height: 14px) {
  &::-webkit-scrollbar {
    width: $width;
    height: $height;
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
    border: 4px solid color($var-color-app-dark-blue-2);
    border-radius: 7px;
  }

  scrollbar-width: thin;
  scrollbar-color: $thumb-color $track-color;
}
