@import "src/view/styles/colors";

.basic-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;

  color: color($var-color-app-white);
  font-weight: 600;
  line-height: 20px;

  background-color: color($var-color-app-dark-blue);
  border: none;
  border-radius: 8px;
  box-shadow: 0 9px 18px 0 #454a5466;

  &:hover {
    background-color: color($var-color-app-dark-blue-2);
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .icon {
      display: flex;
      align-items: center;
    }

    .icon + .title {
      margin-left: 0.5rem;
    }
  }

  &.disabled {
    color: color($var-color-app-grey-2);

    background-color: color($var-color-app-grey-3);
    box-shadow: none;

    button {
      cursor: default;
    }
  }

  &.is-loading {
    pointer-events: none;
  }
}
