.value-container-multi {
  position: relative;

  padding: 0 0 0 16px;

  input[role="combobox"] {
    position: absolute;
  }

  .values {
    padding-right: 10px;
    overflow: hidden;

    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;

    user-select: none;

    .placeholder {
      color: #adb4bd;

      user-select: none;
    }

    &.empty {
      padding-right: 0;
    }
  }

  .counter {
    position: absolute;
    right: 0;

    color: #adb4bd;
    line-height: 20px;

    user-select: none;
  }
}
