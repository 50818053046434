@import "src/view/styles/colors";

.side-menu-header {
  padding-top: 16px;

  .side-menu-filters {
    display: flex;
    flex-direction: column;
    width: 100%;

    > * {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
}
