.report-columns-form {
  display: grid;
  grid-template-rows: 1fr auto;

  form {
    //

    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;

    .is-column-not-found {
      padding-left: 40px;

      text-align: center;
    }
  }
}
