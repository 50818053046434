@import "src/view/styles/colors";

.menu-header {
  //

  .input-wrapper {
    padding: 6px 6px 0;
  }

  .menu-subheader {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;

    .visibility-button {
      width: fit-content;

      color: color($var-color-app-grey-2);

      &:hover {
        color: color($var-color-app-dark-blue-3);
      }
    }

    .selected-options-counter {
      color: color($var-color-app-blue-main);
      user-select: none;

      &.full {
        color: color($var-color-app-orange);
      }
    }
  }
}
