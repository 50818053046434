@import "src/view/styles/colors";

.date-picker-menu {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;

  min-height: inherit;
  padding: 20px;

  .header {
    margin-bottom: 24px;
  }

  .form {
    display: grid;
    grid-template-columns: repeat(3, 180px);
    gap: 20px;
    margin-bottom: 10px;

    .part {
      //

      .part-title {
        margin-bottom: 10px;

        color: color($var-color-app-dark-blue);
        line-height: 20px;
      }

      .input {
        //
        margin-bottom: 10px;
      }

      .input-placeholder {
        height: 40px;
        margin-bottom: 30px;

        background-color: color($var-color-app-white);
        border: 1px dashed color($var-color-app-grey-1);
        border-radius: 8px;

        &:not(:last-child) {
          //
        }
      }
    }
  }

  .footer {
    //
  }
}
