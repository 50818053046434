@import "src/view/styles/colors";

.side-menu-reports {
  height: 100%;
  overflow: hidden auto;

  .no-reports {
    width: 100%;
    padding: 16px;

    color: color($var-color-app-grey-2);
  }

  .group-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    margin-bottom: 5px;
    padding-right: 16px;
    padding-left: 16px;

    cursor: pointer;

    .group-name {
      padding-right: 12px;
      overflow: hidden;

      color: color($var-color-app-blue-main);
      font-weight: 700;
      font-size: 16px;
      line-height: 18.4px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .group-open-button {
      display: flex;
      align-items: center;
      justify-content: center;

      .group-icon-rotated {
        transform: rotate(180deg);
      }
    }
  }

  .detail-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    padding-right: 16px;

    cursor: pointer;

    .icons {
      display: flex;
      gap: 8px;
      padding-left: 12px;

      .info-icon svg {
        width: 17px;
        height: 17px;
      }

      .report-type-icon,
      .info-icon {
        display: flex;
        align-items: center;

        cursor: pointer;

        svg {
          color: color($var-color-app-grey-2);
        }
      }
    }

    .report-name {
      padding-left: 32px;
      overflow: hidden;

      color: color($var-color-app-black);
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:hover {
      background-color: #eaf3f4;
    }

    &.active {
      background-color: color($var-color-app-blue-main);
      cursor: default;

      .report-name {
        color: color($var-color-app-white);
      }

      .icons {
        //

        .report-type-icon svg,
        .info-icon svg {
          color: color($var-color-app-dark-blue-3);
        }
      }
    }
  }
}
