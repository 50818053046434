@import "src/view/styles/colors.scss";

.main {
  height: 100%;

  background-color: color($var-color-app-bg);

  .layout {
    display: grid;
    grid-template-rows: 1fr;

    grid-template-columns: auto 1fr;
    width: 100%;
    height: 100%;

    .side-menu {
      grid-column: 1 / 2;
    }

    .body {
      grid-column: 2 / 3;
      width: 100%;

      overflow: hidden;
    }
  }
}
