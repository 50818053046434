@import "src/view/styles/colors";

.report-filters {
  min-width: 110px;
  height: 60px;

  .more-filters-label {
    display: flex;
    align-items: flex-end;
    width: 120px;
    height: 100%;
    padding-bottom: 10px;

    color: #adb4bd;
    line-height: 20px;
    white-space: nowrap;
  }
}

.loading {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.no-filters {
  color: color($var-color-app-grey-2);
}
