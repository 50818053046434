.edit-report-columns-popup {
  display: grid;
  grid-template-rows: auto 1fr;
  width: 640px;
  height: 100%;
  padding: 20px;

  .popup-header {
    margin-bottom: 24px;
  }
}
