@import "src/view/styles/colors";

.description {
  display: flex;
  flex-direction: column;
  width: 100%;

  .description-title {
    color: color($var-color-app-dark-blue);
    font-weight: 700;
    font-size: 16px;
    line-height: 18.4px;
    text-align: center;
  }

  .description-text {
    padding-top: 8px;

    color: color($var-color-app-black);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
}
